.Banner {
    background-color: #4d4d4d !important;
}

.BannerItem {
    color: white !important;
    text-transform: uppercase !important;
}

.BannerTitreContainer{
    display: flex;
    flex-flow: column;
    align-items: flex-start;
    
}
.BannerTitre {
    color: white !important;
    margin:0 !important;
    margin-left:1rem !important;
    text-transform: uppercase !important;
}
