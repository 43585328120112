.MainContainer {
    padding-top: 100px;
    padding-bottom: 50px;
    padding: 100px 20px 50px 20px;
    // background-color: #fff;
}

// Hack temporaire
@media (max-width: 768px) {
    .MainContainer {
        padding-top: 210px;
    }
}