@font-face {
    font-family: 'Roboto';
    src: local('Roboto'), url(./assets/fonts/Roboto/Roboto-Regular.ttf) format('truetype');
}
@font-face {
    font-family: 'Roboto-Bold';
    src: local('Roboto-Bold'), url(./assets/fonts/Roboto/Roboto-Bold.ttf) format('truetype');
}
@font-face {
    font-family: 'RobotoCondensed';
    src: local('RobotoCondensed'), url(./assets/fonts/Roboto_Condensed/RobotoCondensed-Regular.ttf) format('truetype');
}


body {
    font-family: Roboto, Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
    // background-color: rgb(246, 246, 246);
}

.ui.header.large {
    font-family: RobotoCondensed;
}
